import axios from "axios";
import Config from "../../config";

const actionListaUsuariosOnlineApp = () => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/admin/app/usuarios-ativos`
    });
};

export default actionListaUsuariosOnlineApp;
