import axios from "axios";
import Config from "../../config";

const listaTodosUsuariosBossByClienteId = clienteId => {
    return axios({
        method: "get",
        url: (Config.urlDev || '') + "/api/v1/usuarios/lista/boss/" + clienteId + "/id"
    });
};

export default listaTodosUsuariosBossByClienteId;
