import axios from "axios";
import Config from "../config";

const criarChat = (data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/chats/chat/criar`,
        data
    });
};

export default criarChat;